<template>


<div class="row">
    <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group">
            <label>Precio de venta <strong>${{ $numberFormat(total) }}</strong></label>
            <div class="progress">
                <div class="progress-bar bg-gradient-success" role="progressbar" v-bind:style="'width:100%'" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group">
            <label>Enganche <strong>${{ $numberFormat(down) }}</strong></label>
            <div class="progress">
                <div class="progress-bar bg-gradient-warning" role="progressbar" v-bind:style="'width:'+downPercent+'%'" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4">
        <div class="form-group">
            <label>Monto a financiar <strong>${{ $numberFormat(calculateBalance) }}</strong></label>
            <div class="progress">
                <div class="progress-bar bg-gradient-danger" role="progressbar" v-bind:style="'width:'+balancePercent+'%'" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    </div>
</div>


</template>

<script>


export default{
    name:'balance',
    props:{

        total:{
            type:Number,
            required:true
        },
        down:{
            type:Number,
            required:true
        }
    },

    computed:{


        calculateBalance(){

            return this.total-this.down;
        },

        downPercent(){


            return this.down*100/this.total;


        },


        balancePercent(){

            return this.calculateBalance*100/this.total;


        }

        


    },




}


</script>