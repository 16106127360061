<template>

<section class="map-wrapper">


<div class="row">
    <div class="col-md-3 col-sm-3 col-lg-3">

        <b-tabs v-if="developments.length>0" pills vertical v-model="currentTab" ref="devTabs" @input="onDevChange" content-class="mt-3">
            <b-tab v-for="(item,index) in listDevelopments" :key="index">
                <template slot="title">
                    <i class="mdi mdi-office-building-cog"></i>
                    {{ item.name }}
                </template>
            </b-tab>
        </b-tabs>
    </div>
    <div class="col-md-9 col-sm-9 col-lg-9">
        <div class="wrapper-properties">
            <div v-for="(prop,pindex) in listProperties" :key="pindex" class="item-prop">
                 
                    <input type="radio" :id="'prop'+pindex" :value="prop.id" v-model="property_id" v-bind:disabled="isDisabled(prop)"/>
                    <label :for="'prop'+pindex" v-bind:class="isDisabled(prop)?'disabled':''">
                        <i class="mdi mdi-home-outline 4x"></i>
                        <span>{{ prop.code }}</span>

                    </label>
                
            </div>
        </div>

    </div>

</div>
    

</section>

</template>

<script>

import api from '@/util/api.js';

export default{

    name:'developmentMap',
    routeName:'developments',
    props:{
        id:{
            type:Number,
            required:false
        },
        development_id:{
            type:Number,
            required:false

        }

    },

    data(){


        return {
            currentTab:0,
            developments:[],
            properties:[],
            property_id:0,
            selected_property:0
        }


    },

    computed:{


        listDevelopments(){

            return this.developments;

        },

        listProperties(){
            return this.properties;
        }

    },

    methods:{


        isDisabled(item){

            if(item.status!='available'){

                 if(item.id==this.selected_property){
                    return false;
                }

                return true;

            }
            return false;

        },
        findPropertyById(id){

            return this.properties.find((item)=>item.id==id);


        },

        
        onDevChange(index){


            console.log('index',index);
                const dev=this.developments[index];  
                if(dev){
                    this.loadProperties(dev.id);
                }
            
        },  
        loadProperties(id){


                api.get('/properties?filter[development_id]='+id+'&expand=design').then(response=>{
                    
                    this.properties=response.data;
                }).catch(error=>{
                    console.log(error);
                }).finally(()=>{


                });


        },

        getItems(){

            api.get(this.me()).then(response=>{

                this.developments=response.data;

                this.currentTab=this.developments.findIndex((obj)=>{
                    return obj.id==this.$props.development_id;
                });

                this.loadProperties(this.$props.development_id);
                
                

            }).catch(error=>{

                console.log(error);

            });


        }


    },  


    watch:{


        property_id:function(v){

            const item=this.findPropertyById(v);
            if(item!=undefined){
                this.$emit('input',item)
            }
            
        }
    

    },  
    mounted(){

        
        this.property_id=this.$props.id;
        this.selected_property=this.$props.id;
        this.getItems();


    }


    }


</script>


<style lang="scss" scoped>

.wrapper-properties{margin-top:3px}

</style>