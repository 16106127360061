<template>


        <section class="sale-wrapper">

            <form ref="formSale" @submit.prevent="save">


                <goodWizard
                    ref="saleWizard"
                    :steps="steps"
                    :onNext="nextClicked"
                    :onBack="backClicked"
                    :onSave="saveNow"
                    :showSave="canShowSave"
                    :previousStepLabel="'Anterior'"
                    :nextStepLabel="'Siguiente'"
                    :finalStepLabel="'Guardar'"
                    >
                    <div slot="page1">
                        <developmentMap @input="onPropertySelected" v-if="isModelReady" :development_id="model.development_id" :id="model.property.id"/>
                    </div>
                    <div slot="page2">

                        

                        
                        <blockquote class="blockquote">
                        <div class="row">


                            <div class="col-md-4 col-sm-4 col-lg-4">
                                        
                                        <img v-bind:src="$pic(model.property.design.image)"/>

                                
                            </div>
                            <div class="col-md-8 col-sm-8 col-lg-8">
                                <h4 class="font-weight-normal"><i class="mdi mdi-home-outline mdi-24px"></i> Modelo: {{ model.property.design.name }}
                                        </h4>
                                        <h2 class=""><small>Precio de catálogo:</small> ${{ $numberFormat(model.property.design.price) }}</h2>
                                        <h6>Número: {{ model.property.code }}</h6>



                            </div>

                        </div>
                        </blockquote>


                         

                                <div class="prop-payment-options">


                                    <blockquote class="blockquote">


                                        <div class="row">

                                            <div class="col-md-6 col-sm-6 col-lg-6">

                                              
                                              
                                              
                                                
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-lg-6">

                                                <div class="prop-payment-selector">
                                                    <h5>Elige la forma de pago</h5>
                                                    <div class="payment-radio form-check"><label for="cash" class="form-check-label">De contado <input  type="radio" name="paymentoptions" v-model="model.payment_option" value="cash" class="form-check-input" id="cash" checked/><i class="input-helper"></i></label></div>
                                                    <div class="payment-radio form-check"><label class="form-check-label" for="credit">A plazos <input  type="radio" name="paymentoptions" class="form-check-input" v-model="model.payment_option" value="credit" id="credit"/><i class="input-helper"></i></label></div>
                                                </div>  
                                            </div>


                                        </div>
                                    </blockquote>

                                    <blockquote class="blockquote">

                                        <div class="row" v-if="isCredit">
                                            <div class="col-md-8 col-sm-8 col-lg-8">

                                                <div class="credit-option" >
                                                    <div class="form-group">
                                                        <h5>Enganche</h5>
                                                        <downPayment v-if="isModelReady" :initial_value="model.down_payment_percentage" :mode="'percent'" @input="onDownPaymentChange"/>
                                                    </div>
                                                </div>

                                            </div>


                                            <div class="col-md-4 col-sm-4 col-lg-4">

                                                        <div class="fund-target" v-if="isCredit">
                                                            <div class="form-group">
                                                                    <h5>Pagar enganche en</h5>
                                                                    <select name="fund_id" required v-model="model.fund_id" class="form-control">
                                                                        <option v-for="(item,index) in getFunds" v-bind:value="item.id" :key="index">
                                                                            {{ item.name }}
                                                                        </option>

                                                                    </select>
                                                            </div>
                                                        </div>

                                            </div>


                                        </div>



                                        <div class="row" v-if="isCash">

                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <h5>Fecha de pago</h5>
                                                <Datepicker :position="'top'" lang="es" :inputClass="'form-control form-control-lg'" v-model="model.payment_date" placeholder="Selecciona la fecha"/>
                                            </div>
                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <div v-if="isCash" class="fund-target">
                                                    <div class="form-group">
                                                            <h5>Pagar total en</h5>
                                                            <select name="fund_id" required v-model="model.fund_id" class="form-control">
                                                                <option v-for="(item,index) in getFunds" v-bind:value="item.id" :key="index">
                                                                    {{ item.name }}
                                                                </option>

                                                            </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>





                                    </blockquote>



                                    <blockquote class="blockquote" v-if="isCredit">

                                        <balance :total="getSalePrice" :down="calculateDown"/>
                                    
                                    </blockquote>



                                    <blockquote class="blockquote" v-if="isCredit">

                                        <div class="row">
                                            <div class="col-md-6 col-sm-6 col-lg-6">    
                                                <h5>Plazo</h5>
                                                <numberCounter @input="onCount" :min="min_count" :max="100" :value="counter"/>
                                            </div>

                                            <div class="col-md-6 col-sm-6 col-lg-6">
                                                <h5>Fecha de inicio de pago</h5>
                                                <Datepicker :position="'top'" :inputClass="'form-control form-control-lg'" lang="es" v-model="model.payment_date" placeholder="Selecciona la fecha"/>
                                                
                                            </div>

                                        </div>


                                    </blockquote>




                                    <blockquote class="blockquote" v-if="isCredit">
                                        


                                        <div class="installments">

                                            <h5>Calendario de pagos</h5>
                                            <br>
                                            <installments ref="installments" v-if="isModelReady" @change="onInstallmentChange" :payments="model.payments" :counter="counter" :now="getPaymentDate" :balance="calculateBalance"/>



                                        </div>


                                    </blockquote>

                                        




                                </div>


                    </div><!---slot-->


                    <div slot="page3">                        
                        <h4>Datos del cliente</h4>
                        <formClient ref="formClient"  @model="onClientModel" v-if="isModelReady" :id="model.client.id" :modelobject="model.client" :showSearch="true" :showSubmit="false"/>
                    </div>


<!--
                    <div slot="page4">

                        <div class="form-group">
                            <label>Contrato y documentación</label>

                            <input type="file" multiple class="form-control form-control-lg" v-on:change="selectFile" ref="contracts" accept=".pdf,.docx,.doc"/>

                            <div v-for="(item,index) in model.documents" :key="index">

                                <a :href="fil(item.file)"><i :class="fileico(item.file)"></i> {{item.name}}</a>


                            </div>

                        </div>

                    </div>-->


                    
                </goodWizard>





<!--

                <div class="form-group">
                    <submitbutton :label="isNewRecord?'Crear':'Actualizar'" :loading_flag="loading"/>
                </div>
-->


            </form>

    </section>

</template>
<script>

//import submitbutton from '@/components/ui-feature/submit-button';
//import VueGoodWizard from 'vue-good-wizard';

import goodWizard from '@/components/stepper';
import api from '@/util/api.js';
import formClient from '@/pages/clients/_form';
import downPayment from './downPayment';
import installments from './installments';
import developmentMap from '@/pages/developments/map';
import numberCounter from '@/pages/sales/counter';

import balance from '@/pages/sales/balance';





export default {
    routeName:'sales',
    name: 'formSale',
    components:{
        goodWizard,
        formClient,
        downPayment,
        developmentMap,
        installments,
        numberCounter,
        balance    
    
    },
    props:{

        id:{
            type:Number,
            required:false,
        }

    },  
    
    data(){

        return {

            finalstep:false,
            reload:0,
            sale_counter:0,
            counter:0,
            down_amount:0,
            down_type:'percent',
            down_percent:10,
            editable_price:false,
            cached_price:0,
            indexform:0,
            total_amount:0,
            min_count:0,
            funds:[],
            currentPage:0,
            model:{
                id:0,
                name:'',
                client_id:0,
                property_id:0,
                development_id:0,
                documents:[],
                property:{design:{image:'',price:0}},
                client:{},
                payments:[],
                catalog_price:0,
                sale_price:0,
                down_payment:0,
                payment_option:'cash',
                fund_id:0,
                payment_date:new Date(),
                down_payment_percentage:10
                
            },
            
            pic:'',
            loading:false,
            steps: [
                    {
                    label: 'Unidad',
                    slot: 'page1',
                    },
                    {
                    label: 'Forma de pago',
                    slot: 'page2',
                    },
                    {
                    label: 'Datos del cliente',
                    slot: 'page3',
                    }
                ],
            currentTab:0,


                
        }

    },


    computed:{



        isModelReady(){
        
            return this.isNewRecord || (this.model.property.design.id!=undefined);

        },


        canShowSave(){

            
            return ((!this.isNewRecord && this.currentPage!=0) || (this.isNewRecord && this.finalstep))?true:false;

        },


        getModelPayments(){

            return this.model.payments

        },


        getSalePrice(){

            return this.$float(this.model.sale_price);

        },
        getCounter(){
            return !this.counter?0:this.counter;
        },  

        calculateBalance(){

            const price=this.$float(this.model.sale_price);
            return price-this.calculateDown;
            
        },
        
        calculateDown(){
            
            const price=this.$float(this.model.sale_price);
            return this.down_percent*price/100;
            
        },



        getDeposit(){

            const price=this.$float(this.model.sale_price);
            if(this.isCash){
                return this.$numberFormat(price);
            }else{
                if(this.down_type=='percent'){
                    const p=this.down_percent*price/100;
                    return this.$numberFormat(p);
                }else{
                    const p=this.down_amount;
                    return this.$numberFormat(p);

                }
            }

        },

        getPropertyPrice(){

            return this.$float(this.model.property.design.price);

        },
        isCash(){
            return this.model.payment_option=='cash';
        },
        isCredit(){

            return this.model.payment_option=='credit';
        },

        getFunds(){

             return this.funds;

        },


        getPaymentDate(){

            const payment_date=this.model.payment_date;
            if(typeof(payment_date)!='string'){
                return this.$dateToString(payment_date);
            }
            return payment_date;
        }

    },

    methods:{
        saveNow(){

            this.save();
        },

        editPrice(){

            this.cached_price=this.model.sale_price;
            this.editable_price=true;
        },
        cancelEditPrice(){
            this.model.sale_price=this.cached_price;
            this.editable_price=false;
        },
        savePrice(){

            this.editable_price=false;

        },
        onCount(v){
            this.counter=v;
        },

        
        onDownPaymentChange(it){

            
            this.down_amount=it.amount;
            this.down_percent=it.percent;
            
            this.down_type=it.type;
            this.model.down_payment_percentage=it.percent;
            this.setDownPayment(it);
        },




        setDownPayment(it){

        
            if(it.type=='amount'){
                this.model.down_payment=it.amount;
            }else{

                const price=this.$float(this.model.sale_price);
                this.model.down_payment=(this.down_percent*price)/100;
            }



        },
        

        setTotalAmount(v){
            this.total_amount=v;
        },

        getCatalogs(){

            api.get(this.me('/parentcatalogs')).then(response=>{

                this.funds=response.data.funds;
                //this.now=response.data.date;

            }).catch(error=>{


                console.log(error);

            }).finally(()=>{



            });

        },

        onPropertySelected(item){


            this.model.property=item;
            this.model.property_id=item.id;
            this.model.catalog_price=item.design.price;
            //if(this.isNewRecord){
            this.model.sale_price=this.model.catalog_price;
            //}
            this.model.development_id=item.development_id;

            this.restartPayments();
            

        },

        restartPayments(){
            this.counter=0;
            this.$refs.installments.clearFixedItems();
            this.$refs.installments.clearItems();
            
        },

        onClientModel(model){
            
            this.model.client_id=model.id;
            this.model.client=model;

        },



        selectFile(event) {
         this.model.documents = event.target.files;
        },

        nextClicked(currentPage) {


            
            
            if(currentPage==0 && !this.model.property.id){
                alert('Elige una propiedad');
                return false;
            }
            else if(currentPage==1){


                this.clearFormErrors(this.$refs.formSale);
                if(!this.model.fund_id){
                    alert('No elegiste el destino del pago');
                    this.setFormError(this.$refs.formSale,'fund_id','Elige la forma de pago');
                    return false;
                }else if(this.model.payment_option=='credit' && this.counter==0){
                    alert('Agrega al menos un pago');
                    return false;
                }

                this.finalstep=true;
                return true;


            }
            else if(currentPage==2){

                    return this.validateForm(this.$refs.formClient.$refs.formInnerClient);

            }
            
            else if(currentPage==3){

                
                

                this.$refs.saleWizard.waiting=true;
                this.save();


            }

            this.currentPage=currentPage;
            return true; //return false if you want to prevent moving to next page
        },
        backClicked(currentPage) {
            this.currentPage=currentPage;
            return true; //return false if you want to prevent moving to previous page
        },

        onInstallmentChange(items){
            this.model.payments=items;
        },


        save(){


            this.model.payment_date=this.$dateToString(this.model.payment_date);
            
            if(typeof(this.model.client.birthday)!='string'){
                this.model.client.birthday=this.$dateToString(this.model.client.birthday);
            }


            const data = this.createFormData(this.model,['documents']);
            
            const array_files=this.model.documents;
            for (let i = 0; i < array_files.length; i++) {
                data.append('documents[]', array_files[i]);
            }



            api.post(this.me('/createsale'),data).then(()=>{

                
                this.$notify({message:"Elemento creado correctamente",type:"success"});
                this.$router.push(this.me());

            }).catch(error=>{

                this.processError(error);


            }).finally(()=>{
                this.$refs.saleWizard.waiting=false;

            });


            
        },


        processError(error){


            const data=error.response.data;

            let message='Ocurrió un error intenta de nuevo más tarde';
            if(data.type=='client'){

                this.$refs.saleWizard.goTo(2);
                const err=data.errors;

                for(let i in err){

                    this.setFormError(this.$refs.formClient.$refs.formInnerClient,i,err[i]);
                    

                }

                message='Hubo algunos problemas con los datos del cliente ';

                
            }
            this.$notify({message:message,type:"error"});



        },







    },


    
    
    mounted(){

        

        if(!this.isNewRecord){

            api.get(this.me('/'+this.$props.id+'?expand=property,property.design,client,payments,documents')).then(response=>{

                                
//                this.model=response.data;

                
 
                const payments=response.data.payments;
                this.fillModel(this.model,response.data);

                
                this.model.payment_date=this.$stringToDate(response.data.payments[0].due_date.split(" ")[0]);
                
            
                
                this.model.sale_price=this.$numberFormat(this.model.sale_price);
                this.model.fund_id=this.model.payments[0].fund_id;

                
                this.model.payments=payments;
                this.min_count=payments.reduce(function(total,el){
                    
                    if(el.completed==1 && el.payment_type=='installment'){
                        total++;
                    }
                    return total;
                    
                },0);
                
                this.counter=response.data.total_payments;
                



            });


        }

        this.getCatalogs();





    },



}

</script>
<style lang="scss" scoped>
.sale-price-input{}
.prop-payment-selector{
    
    .payment-radio{
        display: inline-block;
        margin:10px 10px 0 0

    }


}

</style>
